import { CONTENT_TYPE } from './content-type.js';

/**
 * @typedef {import('./content-type.js').ContentType} ContentType
 * @typedef {import('./content-type.js').ContentTypeFilter} ContentTypeFilter
 */

/**
 * @typedef {Object} SliceContentType
 * @property {ContentType} contentType
 * @property {number} [count]
 * @property {ContentTypeFilter} [filter]
 */

/**
 * @type {Object<string, SliceContentType[]>}
 */
export const SLICE_FETCH = {};

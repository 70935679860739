import { PAGE_TYPE } from 'helpers/storyblok/index.js';

import { PageLayout } from './page.js';

const LAYOUT = {
  [PAGE_TYPE.PAGE]: PageLayout,
};

const DEFAULT_LAYOUT = LAYOUT.page;

export const getLayout = (data) => {
  return LAYOUT[data?.story?.content?.component] || DEFAULT_LAYOUT;
};

// import dynamic from 'next/dynamic';

// import { Loading } from '@/components/loading';

import Contact from './contact';
import Cta from './cta';
import Hero from './hero';
import ImageText from './image-text';
import InfoCards from './info-cards';
import ListProducts from './list-products';
import NotFound from './not-found';
import Promotion from './promotion';
import SliderSection from './slider-section';
import StoryPreview from './story-preview';
import TextSection from './text-section';
import TitleSection from './title-section';
import Tracking from './tracking';
import VideoSection from './video-section';

const SLICE = {
  // 'all-editions': AllEditions,
  // 'alphabetical-artists': dynamic(
  //   () => {
  //     return import('./alphabetical-artists').then(
  //       (module) => module.AlphabeticalArtists,
  //     );
  //   },
  //   { loading: Loading },
  // ),
  hero: Hero,
  'title-section': TitleSection,
  'video-section': VideoSection,
  'info-cards': InfoCards,
  'story-preview': StoryPreview,
  'slider-section': SliderSection,
  'image-text': ImageText,
  contact: Contact,
  'text-section': TextSection,
  'not-found': NotFound,
  'list-products': ListProducts,
  tracking: Tracking,
  cta: Cta,

  promotion: Promotion,
};

export const getSlice = (name) => {
  return SLICE[name] || null;
};

import { CONTENT_TYPE } from '../../config/content-type.js';
import { DEFAULT_LOCALE, LOCALE, LOCALES } from '../../config/index.js';
import { SLICE_FETCH } from '../../config/slice.js';

import { fetchStories, fetchStory } from './graphql.js';
import { STORYBLOK_VERSION } from './version.js';

/**
 * @typedef {import('./version.js').StoryblokVersion} StoryblokVersion
 */

/**
 * @typedef {import('./graphql.js').FetchStoriesParams} FetchStoriesParams
 */

export { STORYBLOK_VERSION };

export const PAGE_TYPE = {
  PAGE: 'page',
  PRODUCT: 'product',
  RECIPE: 'recipe',
};

export const STORYBLOK_URL = 'https://api.storyblok.com';

export const RESOLVE_RELATIONS = [];

export const getLocale = (context) => {
  return context.params.slug && context.params.slug.length > 0
    ? LOCALES.find((locale) => {
        return locale.shortCode === context.params.slug[0];
      }) || DEFAULT_LOCALE
    : DEFAULT_LOCALE;
};

/**
 * @param {Object} route
 * @returns {Promise<Object>}
 */
const fetchLayouts = async ({ route, ...rest }) => {
  return (
    await fetchStories({
      startsWith: `${route.lang}/layouts/`,
      isStartpage: false,
      ...rest,
    })
  ).reduce((current, story) => {
    return {
      ...current,
      [story.slug]: story,
    };
  }, {});
};

/**
 * @typedef {Object} FetchAllContentTypes
 * @property {Object<string, number>} counts
 */

/**
 * @async
 * @param {FetchStoriesParams & FetchAllContentTypes} params
 * @return {Promise<object>}
 */
const fetchAllContentTypes = async ({ counts = {}, ...rest }) => {
  const result = {};

  for (const contentType of Object.values(CONTENT_TYPE)) {
    const limit = counts[contentType.key];

    const stories = await fetchStories({
      limit,
      contentType,
      ...rest,
    });

    result[contentType.key] = stories;
  }

  return result;
};

/**
 * @param {Object} route
 * @param {StoryblokVersion|null} [storyblokVersion=null]
 * @param {boolean} [unstableCache=false]
 * @returns {Promise<Object>} blok
 */
export const fetchStoryblok = async (
  route,
  storyblokVersion = null,
  unstableCache = false,
) => {
  /**
   * @type {StoryblokVersion}
   */
  const version = storyblokVersion || process.env.STORYBLOK_VERSION;

  const layouts = await fetchLayouts({
    route,
    version,
    unstableCache,
  });

  const story = await fetchStory({
    bySlugs: [`${route.lang}/${route.path}`, `${route.lang}/${route.path}/`],
    resolveRelations: RESOLVE_RELATIONS,
    version,
    unstableCache,
  });

  // Get Special Types
  let contentTypes = {};

  let data = {
    story,
  };

  // Verify if page is contentType
  const pageType = story?.content?.component;
  const pageLayout =
    data.product && layouts.product ? layouts.product : layouts[pageType];

  const layoutSlices = pageLayout?.content?.body || [];

  if (
    story?.content?.body ||
    pageLayout?.content?.blocks ||
    pageLayout?.content?.body
  ) {
    const values = Object.entries(SLICE_FETCH)
      .filter(([key]) => {
        return [
          ...(story?.content?.body ||
            pageLayout?.content?.blocks ||
            pageLayout?.content?.body),
          ...layoutSlices,
        ].find((item) => {
          return item.component === key;
        });
      })
      .map((entries) => {
        return entries[1];
      });

    const uniqueValues = {};
    const uniqueValuesWithFilter = {};

    // Parcourir le tableau initial
    values.forEach((subArray) => {
      subArray.forEach((item) => {
        if (item.filter) {
          if (!uniqueValuesWithFilter[item.filter.key]) {
            uniqueValuesWithFilter[item.filter.key] = {};
          }

          uniqueValuesWithFilter[item.filter.key][item.contentType.key] = {
            count: Math.max(
              item.count || 1,
              uniqueValuesWithFilter[item.filter.key][item.contentType.key]
                ?.count || 0,
            ),
            contentType: item.contentType,
            filter: item.filter,
          };
        } else {
          uniqueValues[item.contentType.key] = Math.max(
            item.count || 1,
            uniqueValues[item.contentType.key] || 0,
          );
        }
      });
    });

    contentTypes = await fetchAllContentTypes({
      counts: uniqueValues,
      startsWith: `${route.lang}/`,
      sortBy: 'content.startAt:desc',
      version,
      unstableCache,
    });

    // Special Types with filter
    for (const [filterKey, values] of Object.entries(uniqueValuesWithFilter)) {
      if (!contentTypes.filteredBy) {
        contentTypes.filteredBy = {};
      }

      if (!contentTypes.filteredBy[filterKey]) {
        contentTypes.filteredBy[filterKey] = {};
      }

      const filter =
        Object.values(CONTENT_TYPE).find((contentType) => {
          return contentType?.filter?.key === filterKey;
        })?.filter || values[filterKey]?.filter;

      if (filter) {
        for (const object of Object.values(values)) {
          contentTypes.filteredBy[filterKey][object.contentType.key] =
            await fetchStories({
              startsWith: `${route.lang}/`,
              sortBy: 'content.startAt:desc',
              contentType: object.contentType,
              limit: object.count,
              filterQueryV2: filter?.queryBuilderV2?.(data),
              filterQuery: filter?.queryBuilder?.(data),
              version,
              unstableCache,
            });
        }
      }
    }
  }

  data = {
    ...data,
    layouts,
    contentTypes,
  };

  return data;
};

export const getPageProps = async (context) => {
  const insideStoryblok =
    typeof context.searchParams?._storyblok !== 'undefined';

  const locale = getLocale(context);

  const slugs = context.params.slug
    ? decodeURIComponent(context.params.slug.join('/')).split('/')
    : [];

  const slug = slugs.slice(
    slugs && slugs?.length > 0 && slugs[0] === locale.shortCode ? 1 : 0,
  );

  const path = (slug && slug.length > 0 ? slug.join('/') : '').replace(
    /^index$/,
    '',
  );

  const lang = locale.shortCode;

  const story = await fetchStoryblok(
    {
      path,
      lang,
    },
    insideStoryblok ? STORYBLOK_VERSION.DRAFT : null,
    true,
  );

  return story;
};

import { storyblokEditable } from '@storyblok/js';
import React from 'react';

import { LongRightArrow } from '@/components/icons';
import { Link } from '@/components/link';
import { MaxWidth } from '@/components/max-width';

import { generateImageProps } from '@/helpers/image';

const ListProductsItem = ({ blok, cta }) => {
  return (
    <div className="list-products-item">
      <div className="list-products-item__image">
        <img
          {...generateImageProps(blok.image, {
            sizes: '(min-width: 769px) 600px, 100vw',
            draggable: 'false',
          })}
        />
      </div>

      <div className="list-products-item__top">
        <h3>{blok.title}</h3>

        <p>{blok.description}</p>
      </div>

      {cta ? <Link link={cta.link}>{cta.text}</Link> : null}
    </div>
  );
};

const ListProducts = ({ blok }) => {
  return (
    <div className="list-products" {...storyblokEditable(blok)}>
      <MaxWidth>
        <div className="list-products__content">
          {blok.products.map((product) => {
            return (
              <ListProductsItem
                key={product._uid}
                blok={product}
                cta={blok.productCta.length > 0 ? blok.productCta[0] : null}
              />
            );
          })}

          <div className="list-products__content__details-card">
            <Link link={blok.cardLink}>
              <h3>{blok.cardTitle}</h3>

              {blok.cardBackground && blok.cardBackground.filename ? (
                <img
                  {...generateImageProps(blok.cardBackground, {
                    sizes: '(min-width: 769px) 150px, 15vw',
                    draggable: 'false',
                  })}
                />
              ) : null}
              <LongRightArrow />
            </Link>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default ListProducts;

import { storyblokEditable } from '@storyblok/js';
import React from 'react';
import { render } from 'storyblok-rich-text-react-renderer';

import { MaxWidth } from '@/components/max-width';

const Tracking = ({ blok, id = '', pushRef = null }) => {
  return (
    <div
      className="tracking"
      id={id}
      ref={(el) =>
        pushRef && typeof pushRef === 'function' ? pushRef(el) : null
      }
      {...storyblokEditable(blok)}
    >
      <div className="tracking__background">
        <img
          src={blok.background.filename}
          alt="background"
          draggable="false"
        />
      </div>
      <MaxWidth width="small">
        {blok.title ? (
          <div className="tracking__title">
            <h2>{blok.title}</h2>
          </div>
        ) : null}
        <div className="tracking__list">
          {blok.steps &&
            blok.steps.map((item) => {
              return (
                <div key={item._uid} className="tracking__step">
                  <div className="tracking__step__number">
                    {item.number ? (
                      <p className="tracking__step__number__value">
                        {item.number}
                      </p>
                    ) : null}
                  </div>
                  <div className="tracking__step__content">
                    {item.title ? (
                      <p className="tracking__step__title">{item.title}</p>
                    ) : null}
                    {item.description ? (
                      <div className="tracking__step__description">
                        {render(item.description)}
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
        </div>
      </MaxWidth>
    </div>
  );
};

export default Tracking;

const ICON_FOLDER = 'icons';

export const ICONS = {
  SUCCESS: `${ICON_FOLDER}/sucess.svg`,
  essaim: `${ICON_FOLDER}/essaim.svg`,
  abeille: `${ICON_FOLDER}/abeille.svg`,
  bocal: `${ICON_FOLDER}/bocal.svg`,
  clock: `/${ICON_FOLDER}/clock.svg`,
  person: `/${ICON_FOLDER}/person.svg`,
  'ice-cream': `/${ICON_FOLDER}/ice-cream.svg`,
  hat: `/${ICON_FOLDER}/hat.svg`,
  recipe: `/${ICON_FOLDER}/recipe.svg`,
};

export const RightArrow = () => {
  return (
    <svg
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5303 6.53033C16.8232 6.23744 16.8232 5.76256 16.5303 5.46967L11.7574 0.6967C11.4645 0.403807 10.9896 0.403807 10.6967 0.6967C10.4038 0.989593 10.4038 1.46447 10.6967 1.75736L14.9393 6L10.6967 10.2426C10.4038 10.5355 10.4038 11.0104 10.6967 11.3033C10.9896 11.5962 11.4645 11.5962 11.7574 11.3033L16.5303 6.53033ZM-6.55671e-08 6.75L16 6.75L16 5.25L6.55671e-08 5.25L-6.55671e-08 6.75Z"
        fill="#000000"
      />
    </svg>
  );
};

export const RightChevron = () => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.229439 0.220115C0.0825295 0.361098 0 0.552285 0 0.751634C0 0.950983 0.0825295 1.14217 0.229439 1.28315L4.10846 5.00454L0.229439 8.72592C0.0866928 8.86771 0.00770617 9.05762 0.00949192 9.25474C0.0112772 9.45186 0.0936918 9.64042 0.238985 9.77981C0.384278 9.91919 0.580825 9.99826 0.786294 9.99997C0.991761 10.0017 1.18971 9.92591 1.33751 9.78896L5.77056 5.53606C5.91747 5.39508 6 5.20389 6 5.00454C6 4.80519 5.91747 4.614 5.77056 4.47302L1.33751 0.220115C1.19055 0.0791756 0.991266 0 0.783473 0C0.57568 0 0.376394 0.0791756 0.229439 0.220115Z"
        fill="black"
      />
    </svg>
  );
};

export const PARTICULARITIES_ICON = {
  liquide: (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.07838 14.1771C9.2263 14.1647 9.35277 14.1061 9.45779 14.0011C9.56281 13.896 9.61508 13.7698 9.61458 13.6224C9.61458 13.4498 9.55911 13.311 9.44818 13.206C9.33724 13.101 9.19549 13.0549 9.02292 13.0677C8.51753 13.1047 7.98134 12.9659 7.41432 12.6513C6.84731 12.3367 6.48984 11.7668 6.34193 10.9414C6.31727 10.8058 6.25268 10.6949 6.14816 10.6086C6.04363 10.5223 5.92332 10.4792 5.78724 10.4792C5.61467 10.4792 5.47292 10.544 5.36198 10.6737C5.25104 10.8033 5.21406 10.9542 5.25104 11.1263C5.46059 12.248 5.95365 13.0492 6.73021 13.5299C7.50677 14.0107 8.28949 14.2264 9.07838 14.1771ZM8.875 16.3958C7.18628 16.3958 5.77787 15.8165 4.64976 14.6578C3.52165 13.4991 2.95784 12.0569 2.95833 10.3312C2.95833 9.0986 3.44843 7.75798 4.42862 6.30939C5.40882 4.86079 6.89094 3.29238 8.875 1.60416C10.8595 3.29287 12.3419 4.86153 13.3221 6.31013C14.3023 7.75872 14.7922 9.09909 14.7917 10.3312C14.7917 12.0569 14.2276 13.4991 13.0995 14.6578C11.9714 15.8165 10.5632 16.3958 8.875 16.3958Z"
        fill="black"
      />
    </svg>
  ),
  ambré: (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.5L15.3612 4.75V13.25L8 17.5L0.638784 13.25V4.75L8 0.5Z"
        fill="#BD5A18"
      />
    </svg>
  ),
  boisé: (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1884_1321)">
        <path
          d="M13.3309 7.65119C13.7688 6.9427 14.0989 6.18686 14.2003 5.40254C14.3696 5.83404 14.45 6.26497 14.45 6.72466C14.45 7.84904 13.9958 8.94548 12.7617 10.2484C12.0308 11.0156 11.3186 11.8411 10.7877 12.6999C10.3497 13.4084 10.0197 14.1643 9.91825 14.9486C9.74892 14.5171 9.66857 14.0862 9.66857 13.6265C9.66857 12.5021 10.1227 11.4057 11.3568 10.1028C12.0878 9.33562 12.8 8.51011 13.3309 7.65119ZM7.5211 4.36118C7.76234 3.97034 7.9661 3.55609 8.09096 3.1283C8.1216 3.29463 8.13607 3.45781 8.13607 3.61506C8.13607 4.42613 7.76752 5.22002 6.90085 6.1564C6.36015 6.73406 5.82087 7.3687 5.41497 8.02633C5.17424 8.41634 4.97083 8.82967 4.8459 9.2565C4.81412 9.08951 4.8 8.92773 4.8 8.77245C4.8 7.96137 5.16856 7.16746 6.03527 6.23105C6.57595 5.65341 7.11521 5.01879 7.5211 4.36118ZM7.97304 10.3488C7.5351 11.0573 7.20504 11.8131 7.10361 12.5974C6.93428 12.1659 6.85393 11.735 6.85393 11.2753C6.85393 10.1509 7.30811 9.05449 8.54223 7.75154C9.27317 6.98439 9.98535 6.15891 10.5162 5.30003C10.9542 4.59154 11.2842 3.8357 11.3857 3.05137C11.555 3.48287 11.6354 3.9138 11.6354 4.3735C11.6354 5.49784 11.1812 6.59427 9.94713 7.89718C9.21617 8.66435 8.50396 9.48986 7.97304 10.3488Z"
          fill="black"
          stroke="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1884_1321">
          <rect
            width="16.7059"
            height="16.7059"
            fill="white"
            transform="translate(0.75 0.125)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
};

export const RateStarIconFilled = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.85351 18.3333L6.20768 12.4792L1.66602 8.54167L7.66601 8.02083L9.99935 2.5L12.3327 8.02083L18.3327 8.54167L13.791 12.4792L15.1452 18.3333L9.99935 15.2292L4.85351 18.3333Z"
        fill="black"
      />
    </svg>
  );
};

export const RateStarIcon = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.85 14.825L10 12.925L13.15 14.85L12.325 11.25L15.1 8.85L11.45 8.525L10 5.125L8.55 8.5L4.9 8.825L7.675 11.25L6.85 14.825ZM3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
        fill="black"
      />
    </svg>
  );
};

export const LongRightArrow = () => {
  return (
    <svg
      width="54"
      height="12"
      viewBox="0 0 54 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M54 6L44 0.226497V11.7735L54 6ZM0 7H45V5H0V7Z" fill="white" />
    </svg>
  );
};

import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import smoothscroll from 'smoothscroll-polyfill';

import { MaxWidth } from '@/components/max-width';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener('resize', handleResize);

      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);
  return windowSize;
};

export const Slider = ({ slides, pushRef = null, ...rest }) => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const [isSlidingTimeout, setIsSlidingTimeout] = useState(null);
  const sliderWrapperRef = useRef(null);
  const sliderRef = useRef(null);
  const slidesRef = useRef([]);
  const [slideWidth, setSlideWidth] = useState(0);
  const windowSize = useWindowSize();
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });

  useEffect(() => {
    if (slidesRef.current.length > 0)
      setSlideWidth(
        parseInt(
          getComputedStyle(slidesRef.current[0]).width.replace('px', ''),
        ),
      );
    setSliderIndex(0);
  }, [windowSize.width, isMobile]);

  useEffect(() => {
    setSlide(sliderIndex);
  }, [sliderIndex]);

  const setSlide = (index) => {
    if (isSlidingTimeout) clearTimeout(isSlidingTimeout);
    setIsSliding(true);
    setIsSlidingTimeout(setTimeout(() => setIsSliding(false), 800));
    sliderRef.current.scrollTo({
      left: slideWidth * index,
      behavior: 'smooth',
    });
  };

  const handleScroll = (event) => {
    if (isSliding) return;

    const scroll = parseInt(event.currentTarget.scrollLeft) + slideWidth / 2;
    const i = parseInt(scroll / slideWidth);
    if (i !== sliderIndex) setSliderIndex(i);
  };

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <div
      className="slider"
      ref={(el) =>
        pushRef && typeof pushRef === 'function' ? pushRef(el) : null
      }
      {...rest}
    >
      {slides?.map((item, index) => {
        return (
          <img
            key={index}
            className={classNames('slider__background', {
              'slider__background--active': index === sliderIndex,
            })}
            {...(item.backgroundImage || {})}
          />
        );
      })}
      <MaxWidth>
        <div className="slider__block">
          <div className="slider__block__model">
            {slides.map((item, index) => {
              return (
                <div
                  key={index}
                  className={classNames('slider__block__model__slide', {
                    'slider__block__model__slide--solo': slides.length < 2,
                  })}
                >
                  <div className="slider__block__model__slide__title">
                    <h3>{item.title}</h3>
                  </div>

                  <div
                    className="slider__block__model__slide__content"
                    {...(item.dangerousHtmlContent
                      ? {
                          dangerouslySetInnerHTML: {
                            __html: item.dangerousHtmlContent,
                          },
                        }
                      : { children: item.content })}
                  />
                </div>
              );
            })}
          </div>

          <div
            className="slider__block__container"
            ref={sliderRef}
            onScroll={handleScroll}
          >
            <div
              className="slider__block__container__wrap"
              ref={sliderWrapperRef}
            >
              {slides.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="slider__block__container__wrap__slide"
                    ref={(el) => slidesRef.current.push(el)}
                  >
                    <div className="slider__block__container__wrap__slide__title">
                      <h3>{item.title}</h3>
                    </div>

                    <div
                      className="slider__block__container__wrap__slide__content"
                      {...(item.dangerousHtmlContent
                        ? {
                            dangerouslySetInnerHTML: {
                              __html: item.dangerousHtmlContent,
                            },
                          }
                        : { children: item.content })}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          {slides.length > 1 ? (
            <div className="slider__block__bottom">
              {slides.map((item, index) => {
                return (
                  <button
                    className={classNames('slider__block__bottom__button', {
                      'slider__block__bottom__button--active':
                        sliderIndex === index,
                    })}
                    key={index}
                    onClick={() => {
                      setSliderIndex(index);
                    }}
                  >
                    <div></div>
                  </button>
                );
              })}
            </div>
          ) : null}
        </div>
      </MaxWidth>
    </div>
  );
};

'use client';

import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import * as EmailValidator from 'email-validator';
import React, { useRef, useState } from 'react';

import { Button } from '@/components/button';
import { ICONS } from '@/components/icons';

import { render } from '@/helpers/storyblok/rich-text';

const STATE = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const ContactForm = ({ blok, isFirst }) => {
  const formRef = useRef(null);

  const [state, setState] = useState(STATE.IDLE);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      site: 'Atelier Gascon',
      firstName: firstName,
      lastName: lastName,
      email: email,
      message: message,
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch('/api/contact', options);

      if (!response.ok) {
        setState(STATE.ERROR);
        Sentry.withScope((scope) => {
          scope.setExtras(data);

          Sentry.captureException(new Error('Contact form submit error'));
        });
      }

      setState(STATE.SUCCESS);
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtras(data);

        Sentry.captureException(new Error(error));
      });
      setState(STATE.ERROR);
    }
  };

  return (
    <div
      className={classnames('contact__form', {
        'contact__form--state-loading': state === STATE.LOADING,
        'contact__form--state-success': state === STATE.SUCCESS,
        'contact__form--state-error': state === STATE.ERROR,
      })}
    >
      <div className="contact__form__title">
        {isFirst ? <h1>{blok.title}</h1> : <h2>{blok.title}</h2>}
      </div>
      <div className="contact__form__description">
        {render(blok.description)}
      </div>

      <form onSubmit={handleSubmit} id="contact-form" ref={formRef}>
        <div className="contact__form__split">
          <div className="contact__form__input-container">
            <label>
              {blok.labelName}
              <br />
              <input
                className="contact__form__input"
                type="text"
                name="lastName"
                autoComplete="family-name"
                value={lastName}
                onInput={(event) => setLastName(event.target.value)}
                readOnly={state === STATE.LOADING}
              />
            </label>
          </div>

          <div className="contact__form__input-container">
            <label>
              {blok.labelFirstName}
              <br />
              <input
                className="contact__form__input"
                type="text"
                name="firstName"
                autoComplete="given-name"
                value={firstName}
                onInput={(event) => setFirstName(event.target.value)}
                readOnly={state === STATE.LOADING}
              />
            </label>
          </div>
        </div>

        <div className="contact__form__input-container">
          <label>
            {blok.labelEmail}
            <br />
            <input
              className="contact__form__input"
              type="email"
              name="email"
              autoComplete="email"
              value={email}
              onInput={(event) => setEmail(event.target.value)}
              readOnly={state === STATE.LOADING}
            />
          </label>
        </div>

        <div className="contact__form__input-container-textarea">
          <label>
            {blok.labelMessage}
            <br />
            <textarea
              className="contact__form__textarea"
              name="message"
              autoComplete="no"
              value={message}
              onInput={(event) => setMessage(event.target.value)}
              readOnly={state === STATE.LOADING}
            />
          </label>
        </div>

        {state === STATE.ERROR ? (
          <div className="contact__form__error">
            {render(blok.errorMessage)}
          </div>
        ) : null}

        <div className="contact__form__cta">
          <Button
            horizontalPadding="large"
            theme="black"
            rightIcon="right-arrow"
            disabled={
              [STATE.LOADING, STATE.SUCCESS].includes(state) ||
              message === '' ||
              email === '' ||
              EmailValidator.validate(email) === false
            }
          >
            {blok.buttonText}
          </Button>
        </div>
      </form>

      {state === STATE.SUCCESS ? (
        <div className="contact__form__success">
          <div className="contact__form__success__content">
            <img src={ICONS.SUCCESS} alt="" />

            {render(blok.successMessage)}
          </div>
          <div className="contact__form__success__button">
            <Button
              horizontalPadding="large"
              theme="black"
              rightIcon="right-arrow"
              field={blok.buttonSuccessLink}
            >
              {blok.buttonSuccessText}
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

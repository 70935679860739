import React from 'react';

import { useData } from '@/helpers/data.js';

import { Footer } from '../footer/index.js';
import { Header } from '../header/index.js';

// import { Footer } from '../footer';
// import { Header } from '../header';
// import { MobileMenu } from '../mobile-menu';

export const Layout = ({ children, locale }) => {
  const { story, layouts } = useData();

  const header = layouts?.header?.content;
  const footer = layouts?.footer?.content;

  const structuredDatas = {
    localBusiness: {
      '@context': 'https://schema.org',
      '@type': ['LocalBusiness'],
      image: [''],
      name: 'Atelier Gascon',
      address: {
        '@type': 'PostalAddress',
        streetAddress: '34 rue de gérand',
        addressLocality: 'Bordeaux',
        addressRegion: 'Nouvelle-Aquitaine',
        postalCode: '33800',
        addressCountry: 'Fr',
      },
      url: '',
      telephone: '',
      /* openingHoursSpecification: [
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Monday'],
          opens: '00:00',
          closes: '00:00',
        },
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Tuesday', 'Wednesday'],
          opens: '10:00',
          closes: '20:30',
        },
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Thursday', 'Friday'],
          opens: '12:00',
          closes: '22:00',
        },
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Saturday'],
          opens: '10:00',
          closes: '22:00',
        },
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Sunday'],
          opens: '10:00',
          closes: '20:00',
        },
      ], */
      acceptsReservations: '',
    },
  };

  return (
    <>
      <Header blok={header} locale={locale} />
      <main className="main">
        <div className="page">
          <div className="page__wrap">
            {Object.values(structuredDatas).map((data, index) => {
              return (
                <script
                  key={index}
                  type="application/ld+json"
                  dangerouslySetInnerHTML={{
                    __html: JSON.stringify(data),
                  }}
                />
              );
            })}
            {children}
            <div className="page__bottom-margin" />
          </div>
        </div>
      </main>
      <Footer blok={footer} />
    </>
  );
};

'use client';

import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

import React, { useState } from 'react';

import { getLayout } from '@/layouts/index.js';

import CookieConsent from '../components/cookie-consent';

import { DataProvider } from '@/helpers/data';
import { useStoryblokBridge } from '@/helpers/hooks/storyblok';

export const PageClient = ({ locale, ...rest }) => {
  const [data, setData] = useState(rest.data);

  useStoryblokBridge({
    data,
    setData,
  });

  const Layout = getLayout(data);

  return (
    <DataProvider
      value={{
        ...data.contentTypes,
        locale,
        story: data.story,
        layouts: data.layouts,
        product: data.product,
        products: data.products,
        contentTypes: data.contentTypes,
      }}
    >
      <Layout />
      <CookieConsent locale={locale} />
    </DataProvider>
  );
};
